
import { defineComponent, ref, PropType } from 'vue'
import { useStore } from 'vuex'
import { baseURL } from '@/API'
import { FileInfo, } from '@/API/types'
import { message } from 'ant-design-vue'
import { data } from './data'
import { saveBlockRecord, } from '@/API/dealerMaster'
import blobDownload from '@/utils/blobDownload'
import { s3Download } from "@/utils/s3Download";
const columns = [
  {
    title: 'Operation Name',
    dataIndex: 'operationType',
    key: 'dealerBlockCode',
    slots: { customRender: 'operationType' },
    align: 'center'
  },
  {
    title: 'Operation User',
    dataIndex: 'operationBy',
    key: 'operationBy',
    align: 'center'
  },
  {
    title: 'Operation Time',
    dataIndex: 'operationTime',
    key: 'operationTime',
    align: 'center'
  },
]
interface LinkTypeProps  {
  docName: string;
  downloadUrl: string;
}
export default defineComponent({
    props: {
        visibleUplload: {
            type: Boolean
        },
        title: {
          type: String
        },
        blockListCode: {
          type: String
        },
        linkData: {
          type: Object as PropType<LinkTypeProps>,
          default: () => {
            return {
              docName: ''
            }
          }
        },
        viewTable: {
          type: Array
        },
        currentRecord: {
          type: Object
        },
        statusList: {
          type: Array
        }
    },
   
    name: '',
    setup(props, context) {
        const store = useStore()
        const fileList = ref()
        const headers = {
            Authorization: store.state.Authorization
        }
        // 关闭的回调
        const onCancelUpload = () => {
            fileList.value = null;
            context.emit('update:visibleUplload', false)
        }

        // 上传的回调
        const visibleModal = ref(false)
        const errorMsg = ref('')
        const uploadParams = ref()
        const uploadChange = (info: FileInfo) => {
            uploadParams.value = null
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
              if ((info.file.response as any).data.message) {
                  visibleModal.value = true
                  errorMsg.value = (info.file.response as any).data.message
              } else {
                // 上传文件成功
                message.success(`${info.file.name} file uploaded successfully`);
                // visible.value = true
                uploadParams.value = (info.file.response as any).data
              }
            } else if (info.file.status === 'error') {
                uploadParams.value = null
                message.error(`${info.file.name} file upload failed.`);
            }
        }
        
        // save
        const handleUpdata = (operationType: number) => {
          const params = {}
          // 上传后
          if (uploadParams.value) {
            const { fileDocInfo: fileInfo, dealerDocInfo: docInfo, parsedDealerBlockDetail:blockDetailDto } = uploadParams.value
            if (operationType) {
              Object.assign(props.currentRecord, {operationType})
            } 
            Object.assign(params, {
              operationType,
              fileInfo,
              docInfo,
              blockDetailDto,
              dealerBlockCode: props.blockListCode,
              ...props.currentRecord
            })
          } else {
            // 直接点击按钮 save需要上传后
              if (!operationType) {
                message.error("Block list file can't be empty!")
                return
              } else {
                // submit
                Object.assign(props.currentRecord, {operationType})
                Object.assign(params, {
                  ...props.currentRecord
                })
              }
          }
         
          saveBlockRecord(params).then(res => {
            context.emit('upload-success')
            fileList.value = null
            uploadParams.value = null
          })
        }

        const uploadBlockFile = () => {
          s3Download({
            url: props.linkData.downloadUrl,
            method: 'get'
          })
        }

        // 下载模板
        const handlerDownloadTemplate = () => {
          blobDownload({ url: `/dapi/dealerBlock/template` })
        }

      

        return {
            onCancelUpload,
            headers,
            baseURL,
            uploadChange,
            visibleModal,
            errorMsg,
            columns,
            data,
            handlerDownloadTemplate,
            handleUpdata,
            uploadBlockFile,
            fileList,
            
        }

    }
 });
