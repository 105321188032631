export const data = [
    {
        dealerBlockCode: '1',
        dealerQty: '0',
        submitTime: '2002-1-2',
        approvedTime: '2002-1-2',
        status: 'Saved',
    },
    {
        dealerBlockCode: '1',
        dealerQty: '0',
        submitTime: '2002-1-2',
        approvedTime: '2002-1-2',
        status: 'submit',
    },
];