
import { defineComponent, ref, reactive, toRefs, onMounted, createVNode } from 'vue'
import CreateBlock from './CreateBlock.vue'
import Pagination from "@/components/Pagination.vue";
import { getDealerBlockList, getBlockCodeList, getDealerBlockLink, deleteBlockRecord, getDealerBlockView, updateAuditState } from '@/API/dealerMaster'
import useFetchCommon from "@/hooks/useFetchCommon";
// import moment from "moment";
import blobDownload from '@/utils/blobDownload'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { MessageProxy, UpdatedStateProxy } from './types'
import useTableHeight from "@/hooks/useTableHeight";
const columns = [
  {
    title: 'Block List Code',
    dataIndex: 'dealerBlockCode',
    key: 'dealerBlockCode',
    align: 'center',
  },
  {
    title: 'Dealer Qty',
    dataIndex: 'dealerQty',
    key: 'dealerQty',
    align: 'center',
  },
  {
    title: 'Submit Time',
    dataIndex: 'submitTime',
    key: 'submitTime',
    align: 'center',
  },
  {
    title: 'Approved Time',
    dataIndex: 'approvedTime',
    key: 'approvedTime',
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'operationType',
    key: 'status',
    align: 'center',
    slots: { customRender: 'operationType' }
  },
  {
    title: 'Operation',
    dataIndex: 'vehicleDisLevel',
    align: 'center',
    
    slots: { customRender: 'operation', title: 'Operation' }
  },
]
const statusList = [
  {
    code: 0,
    display: 'Saved'
  },
  {
    code: 1,
    display: 'Submitted'
  },
  {
    code: 3,
    display: 'Controlling Approved'
  },
  {
    code: 2,
    display: 'Controlling Rejected'
  },
  
]
export default defineComponent({
    name: '',
    components: {
      CreateBlock,
      Pagination
    },
    setup() {
      const { tableHeight } = useTableHeight();
      const queryParams = reactive<any>({
          blockListCode: '',
          approveTime: [],
          submitTime: [],
          status: [],
      }); 
      
      const rowSelection = reactive<{ selectedRowKeys: string[]; selectedRowData: string[]; onChange: Function }>({
          onChange: (selectedRowKeys: string[], selectedRows: string[]) => {
              rowSelection.selectedRowKeys = selectedRowKeys;
              rowSelection.selectedRowData = selectedRows;
          },
          selectedRowKeys: [],
          selectedRowData: []
      })

      // 获取dealer block列表
      const { data, pagination, refreshFetch } = useFetchCommon({params: queryParams, initData: [], fetchApi: getDealerBlockList, isPagination: true})

      // 搜索
      const handlerSearch = () => {
        pagination.page = 1;
        refreshFetch()
      }

      // 重置
      const handlerReset = () => {
          Object.assign(queryParams, {blockListCode: '', approveTime: [], submitTime: [], status: []})
      }

      //分页事件处理
      const handlerPaginationChange = (page: number): void => {
          pagination.page = page;
          refreshFetch(false);
      }

      const handlerShowSizeChange = (current: number, pageSize: number): void => {
          pagination.size = pageSize;
          Object.assign(pagination, { page: current, size: pageSize });
          refreshFetch(false);
      }

      // 获取下载的文件的链接
      const linkData = ref()
      const getFileLink = (docInfo: any) => {
        const {id, docType, docName} = docInfo
        getDealerBlockLink(id, docType, docName).then(res => {
          linkData.value = res
        })
      }

      // 获取view列表的数据
      const viewTable = ref()
      const getViewTable = (blockCode: string) => {
        getDealerBlockView(blockCode).then(res => {
          viewTable.value = res
        })
      }

      // 获取code list
      const blockCode = ref()
      const getCodeList = () => {
        getBlockCodeList().then(res => {
          blockCode.value = res
        })
      }

      // 提示框
      const showConfirm = <T,>(content: string, msg: MessageProxy, params: T, callback?: Function, callbackCode?: Function ) => {
        Modal.confirm({
            title: 'Tips',
            class: 'modal-info ',
            icon: createVNode(ExclamationCircleOutlined),
            content: content,
            closable: true,
            centered: true,
            okText: 'Confirm',
            onOk() {  
                callback && callback(params).then((res: any) => {
                  if (res || res.length === 0) {
                    message.success(msg.success)
                    refreshFetch();
                    callbackCode && callbackCode()
                    rowSelection.selectedRowKeys = []
                    rowSelection.selectedRowData = []
                  } else {
                    message.error(msg.error)
                  }
                }) ;
            },
        });
      };
      
      const visibleUplload = ref(false)
      const modelTitle = ref('Edit Block List')
      const currentCode = ref('')
      const currentRecord = ref()

      // Create
      const handleCreate = () => {
        visibleUplload.value = true
        modelTitle.value = 'Create New Block List'
        currentCode.value = ''
        linkData.value = ''
        currentRecord.value = null
      }

      // 判断勾选的状态是否为submit
      const findStateSubmit = () => {
        let isSubmit = true
        const ids: string[] = []
        rowSelection.selectedRowData.forEach((item: any) => {
          if (item.operationType !== 1) {
            isSubmit = false
          } else {
            ids.push(item.id)
          }
        })
        return {
          isSubmit,
          ids
        }
      }

      // Approve 
      const handleUpdateState = (operationType: number) => {
        const { isSubmit , ids } = findStateSubmit()
        const params: UpdatedStateProxy = {
          operationType,
          ids,
        }
        const msg: MessageProxy = {
          success: operationType === 3 ? 'Approve successfully' : 'Reject sucessfully',
          error: operationType === 3 ? 'Approve failure' : 'Reject failure',
        }
        if (!isSubmit || !ids.length) {
          message.error('Please Select Submitted Data')
        } else {
          showConfirm<UpdatedStateProxy>(`Do you confirm to ${operationType === 3 ? 'approve' : 'reject'} block list?`, msg, params, updateAuditState)
        }
      }

      // Edit 
      const handleEdit = (record: any) => {
        visibleUplload.value = true
        modelTitle.value = 'Edit Block List'
        currentCode.value = record.dealerBlockCode
        currentRecord.value = record
        getFileLink(record.docInfo)
      }
      
      // 删除
      const handleDelete = (id: string) => {
        const msg: MessageProxy = {
          success: 'Delete successfully',
          error: 'Delete failed'
        }
        showConfirm<string>('Do you confirm delete current block list?', msg, id ,deleteBlockRecord, getCodeList)
      }
      
      // 上传成功 更新
      const uploadSuccess = () => {
        visibleUplload.value = false
        refreshFetch();
        getCodeList()
        rowSelection.selectedRowKeys = []
        rowSelection.selectedRowData = []
      }

      // View
      const handleView = (record: any) => {
        visibleUplload.value = true
        modelTitle.value = 'View Block List'
        currentCode.value = record.dealerBlockCode
        currentRecord.value = record
        getFileLink(record.docInfo)
        getViewTable(record.dealerBlockCode)

      }

      // 下载选中文件
      const handlUploadCurrent = () => {
          blobDownload({ url: `/dapi/dealerBlock/export`})
      }

      // 如果没有勾选 默认下载当前页
      const findCurrentCode = () => {
        const res = (data.value as any).map((item: any) => {
            return item.dealerBlockCode
        })
        return res
      }

      // 下载全部文件
      const handlUploadHistory = () => {
        const params =  {
          dealerBlockList: rowSelection.selectedRowKeys
        }
        if (rowSelection.selectedRowKeys.length === 0) {
          Object.assign(params, {dealerBlockList: findCurrentCode()})
        }
        blobDownload({ url: `/dapi/dealerBlock/export`, params})
        rowSelection.selectedRowKeys = []
        rowSelection.selectedRowData = []
      }

      onMounted(() => {
        getCodeList()
      })
      
        return {
          tableHeight,
          columns,
          ...toRefs(queryParams),
          blockCode,
          data,
          rowSelection,
          ...toRefs(rowSelection),
          visibleUplload,

          // 状态list hard coded
          statusList,
          handlerSearch,
          handlerReset,
          // 分页处理
          handlerPaginationChange,
          handlerShowSizeChange,
          ...toRefs(pagination),

          // Edit
          handleCreate,
          handleUpdateState,
          handleEdit,
          handleView,
          modelTitle,
          currentCode,
          linkData,
          handleDelete,
          viewTable,
          uploadSuccess,
          currentRecord,

          handlUploadCurrent,
          handlUploadHistory,
        }

    }
 });
